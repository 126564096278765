import React, {useState, useContext, useEffect} from 'react'
import { DateTime } from 'luxon'
import { navigate } from 'gatsby';
import { InputField, RadioField, SubmitButton } from './library/entitysync'
import { Modal } from './library'
import { ImpactContext } from './Layout'
import getPetTypes from '../utils/getPetTypes'

function ProfileForm({member, label_prefix}) {
    const { user } = useContext(ImpactContext)
    const [loading, setLoading] = useState(false)
    const [changed, setChanged] = useState(user ? user.attributes?.changed : null)

    const petTypes = getPetTypes();

    function goBack(){
        navigate(`/account`)
    }

    function onSubmit(e) {
        setLoading(true);
    }
    
    useEffect(() => {
      const changedNumber = typeof changed !== 'number' ? DateTime.fromISO(changed).toMillis() / 1000 : changed
      if(typeof user.attributes?.changed === 'number'){
          if(user.attributes.changed > changedNumber){
            goBack()
          }
      }
    }, [user])

    return (
        <Modal
            id="edit-profile-child"
            open={true}
            size="xs"
            title="Edit profile"
            handleClose={goBack}
            footer={<SubmitButton variant="contained" onClick={onSubmit} disabled={loading} color="primary">Done</SubmitButton>}
        >
            <InputField 
                label={`${label_prefix} ${member !== 'pet' && !petTypes.includes(member) ? 'first' : ''} name`}
                field={`field_${member}_name`}
                disabled={loading}
            />
            {member !== 'pet' && !petTypes.includes(member) &&  (
                <RadioField 
                    field={`field_${member}_avatar`}
                    label={`${label_prefix} avatar`}
                    avatar
                    disabled={loading}
                />
            )}
        </Modal>
    )
}

export default ProfileForm
