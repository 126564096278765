export default function getPetTypes() {
    return [
        'bella',
        'coco',
        'max',
        // 'coco',
        'lucky',
        'teddy',
        'cooper',
        'stella',
    ];
}